import { createAlertStateAdapter, createPaginatedStateAdapter } from '@locumsnest/core/src';
import { createLoadingStateAdapter } from '@locumsnest/core/src/lib/adapters/loading-state-adapter';
import { MessageableFactory, SignalableFactory } from '@locumsnest/core/src/lib/ngrx';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IPreferredWardEntity } from '../../interfaces/api/preferred-ward-entity';

export const adapter: EntityAdapter<IPreferredWardEntity> =
  createEntityAdapter<IPreferredWardEntity>({});

export const messageableFactory = MessageableFactory.forFeature<'PreferredWard'>('PreferredWard');
export const loadingAdapter = createLoadingStateAdapter<'PreferredWard'>(messageableFactory);
export const signalableFactory = SignalableFactory.forFeature<'PreferredWard'>('PreferredWard');

export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);

export const alertStateAdapter = createAlertStateAdapter(signalableFactory, messageableFactory);
export const { errorHandler, conditionalErrorHandler, alertHandler } =
  alertStateAdapter.getAlertHandlers();
export const { ResetErrorMessage, AlertErrorMessage } = alertStateAdapter.getMessages();

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedPreferredWardId: null,
  selectUnassigned: null,
});
