import { combineReducers } from '@ngrx/store';
import { get, isNil } from 'lodash-es';

import { adapter, initialState, paginationAdapter } from './hospital.adapter';
import { HospitalMessages, HospitalMessageTypes as MessageTypes } from './hospital.messages';
import { IHospitalEntityState } from './interfaces';

export * from './interfaces';

export const paginationReducer = paginationAdapter.createReducer();
export function entityReducer(
  state = initialState,
  action: HospitalMessages
): IHospitalEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }
    case MessageTypes.SET_ASSIGNED: {
      return {
        ...state,
        assignedHospitalId: action.payload.id,
      };
    }
    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }
    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }
    case MessageTypes.UPSERT_CONFIGURATION: {
      const entity = get(state, `entities.${action.payload.id}`, null);
      if (isNil(entity)) {
        return state;
      }
      return adapter.updateOne(
        {
          ...entity,
          configuration: {
            ...action.payload.entity,
          },
        },
        state
      );
    }

    default: {
      return state;
    }
  }
}
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
export function reducer(state, action) {
  return combineReducers({
    entityState: entityReducer,
    pagination: paginationReducer,
  })(state, action);
}
