import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IPreferredWardEntity } from '../../../interfaces/api/preferred-ward-entity';

export enum PreferredWardTempMessageTypes {
  SET_COLLECTION = '[PreferredWardTemp][Message] Set Collection Temp',
  RESET_COLLECTION = '[PreferredWardTemp][Message] Reset Collection Temp',
  ADD_ONE = '[PreferredWardTemp][Message] Add One Temp',
  UPDATE_ONE = '[PreferredWardTemp][Message] Update One Temp',
  UPSERT_ONE = '[PreferredWardTemp][Message] Upsert One Temp',
  DELETE_ONE = '[PreferredWardTemp][Message] Delete One Temp',
  ADD_MULTIPLE = '[PreferredWardTemp][Message] Add All Temp',
  DELETE_MULTIPLE = '[PreferredWardTemp][Message] Delete Many Temp',
  UPSERT_MULTIPLE = '[PreferredWardTemp][Message] Upsert Many Temp',
  UPDATE_MULTIPLE = '[PreferredWardTemp][Message] Update Many Temp',
  SET_SELECT_UNASSIGNED = '[PreferredWardTemp][Message] Set Select Unassigned Temp',
}
export class SetCollectionMessage implements Action {
  readonly type = PreferredWardTempMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IPreferredWardEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = PreferredWardTempMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IPreferredWardEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = PreferredWardTempMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IPreferredWardEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = PreferredWardTempMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IPreferredWardEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = PreferredWardTempMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IPreferredWardEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = PreferredWardTempMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IPreferredWardEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = PreferredWardTempMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IPreferredWardEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = PreferredWardTempMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = PreferredWardTempMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: number[] }) {}
}
export class ResetCollectionMessage implements Action {
  readonly type = PreferredWardTempMessageTypes.RESET_COLLECTION;

  constructor(public payload: Record<string, never>) {}
}

export class SetSelectUnassignedMessage implements Action {
  readonly type = PreferredWardTempMessageTypes.SET_SELECT_UNASSIGNED;

  constructor(public payload: { selectUnassigned: boolean }) {}
}

export type PreferredWardTempMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | ResetCollectionMessage
  | SetSelectUnassignedMessage;
