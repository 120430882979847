import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ISelectOption } from '@locumsnest/components';

import {
  IHospitalDetailsEntity,
  IHospitalEntity,
  IHospitalShiftLockRates,
  IHospitalTimesheetLockRates,
} from '../../interfaces/api/hospital-entity';
import { paginationAdapter } from './hospital.adapter';
import { selectAll, selectEntities } from './hospital.reducer';
import { IHospitalFeatureState, IHospitalSharedState } from './interfaces';

export const getHospitalSector = (hospital: IHospitalEntity) => (hospital ? hospital.sector : null);

export const getHospitalControlContactOfficer = (hospital: IHospitalEntity) =>
  hospital ? hospital.controlContactOfficer : null;

export const getHospitalDetails = (hospital: IHospitalEntity): IHospitalDetailsEntity => {
  let hospitalDetails: IHospitalDetailsEntity;

  if (hospital) {
    hospitalDetails = {
      name: hospital.name,
      payrollOfficer: hospital.payrollEmail,
      locumsNestLead: hospital.email,
      address: hospital.address,
      telephone: hospital.telephone,
      description: hospital.description,
      payrollEmail: hospital.payrollEmail,
      logo: hospital.logo,
      brandGuidelines: hospital.brandGuidelines,
      fragmentRates: hospital.fragmentRates,
      agencySpending: hospital.agencySpending,
      usesAgency: hospital.usesAgency,
      usesBankHolidayRates: hospital.usesBankHolidayRates,
      costCodesV2: hospital.costCodesV2,
    };
  }

  return hospitalDetails;
};

const selectHospitalState = createFeatureSelector<IHospitalFeatureState & IHospitalSharedState>(
  'hospitals',
);

export const selectHospitalEntityState = createSelector(
  selectHospitalState,
  (state) => state.entityState,
);

const selectHospitalEntities = createSelector(selectHospitalEntityState, selectEntities);

export const selectAllHospitals = createSelector(selectHospitalEntityState, selectAll);

export const selectHospitalOptions = createSelector(selectAllHospitals, (hospitals) =>
  hospitals.map((hospital) => ({ id: hospital.id, name: hospital.name }) as ISelectOption),
);

export const selectHospitalOptionsWithUuid = createSelector(selectAllHospitals, (hospitals) =>
  hospitals.map(
    (hospital) => ({ id: hospital.uuid, name: hospital.name }) as ISelectOption<string>,
  ),
);

export const selectHospitalById = (id: number) =>
  createSelector(selectHospitalEntities, (entities) => entities[id]);

export const selectAssignedHospitalId = createSelector(
  selectHospitalEntityState,
  (state) => state.assignedHospitalId,
);

export const selectAssignedHospital = createSelector(
  selectHospitalEntities,
  selectAssignedHospitalId,
  (entities, assignedHospitalId) =>
    selectAssignedHospitalId ? entities[assignedHospitalId] : null,
);

export const selectAssignedHospitalStaffBankCollaborationGroups = createSelector(
  selectAssignedHospital,
  (state) => state?.staffBankCollaborationGroups || [],
);

export const selectAssignedHospitalName = createSelector(
  selectAssignedHospital,
  (state) => state?.name,
);

export const selectHospitalByIds = (ids: number[]) =>
  createSelector(selectHospitalEntities, (entities) =>
    ids.map((id) => entities[id]).filter((x) => !!x),
  );

export const selectHospitalSector = (id: number) =>
  createSelector(selectHospitalById(id), getHospitalSector);

export const selectAssignedHospitalSector = createSelector(
  selectAssignedHospital,
  getHospitalSector,
);

export const selectAssignedHospitalControlContactOfficer = createSelector(
  selectAssignedHospital,
  getHospitalControlContactOfficer,
);

export const selectHospitalDetails = createSelector(selectAssignedHospital, getHospitalDetails);

export const hospitalPaginationSelectors = paginationAdapter.paginationSelectors(
  selectHospitalState,
  null,
  'pagination',
  'entityState',
  false,
);

export const selectHospitalShiftLockRates = createSelector(
  selectAssignedHospital,
  (hospital) =>
    ({
      lockShiftRatesCeiling: hospital?.lockShiftRatesCeiling,
      lockShiftIfRatesViolated: hospital?.lockShiftIfRatesViolated,
    }) as IHospitalShiftLockRates,
);

export const selectHospitalTimesheetLockRates = createSelector(
  selectAssignedHospital,
  (hospital) =>
    ({
      lockTimeSheetRatesCeiling: +hospital?.lockTimeSheetRatesCeiling,
      lockTimeSheetIfRatesViolated: hospital?.lockTimeSheetIfRatesViolated,
    }) as IHospitalTimesheetLockRates,
);

export const selectUseTimeSheetRateViolationReason = createSelector(
  selectAssignedHospital,
  (hospital) => !!hospital?.useTimeSheetRateViolationReason,
);

export const selectAllowBackdatedShifts = createSelector(
  selectAssignedHospital,
  (hospital) => !!hospital?.allowBackdatedShifts,
);

export const selectHasRestrictedOfficers = createSelector(
  selectAssignedHospital,
  (hospital) => !!hospital?.hasRestrictedOfficers,
);

export const selectUsesAgency = createSelector(
  selectAssignedHospital,
  (hospital) => !!hospital?.usesAgency,
);

export const selectAssignedHospitalSpiProVersion = createSelector(
  selectAssignedHospital,
  (hospital) => !!hospital?.spiProVersion,
);

export const selectUsesCostCodesV2 = createSelector(
  selectAssignedHospital,
  (hospital) => hospital?.costCodesV2,
);

export const selectHideBidRates = createSelector(
  selectAssignedHospital,
  (hospital) => hospital?.hideBidRateCandidateDetails,
);

export const selectUsesAgencyInvoicing = createSelector(
  selectAssignedHospital,
  (hospital) => hospital?.usesAgencyInvoicing,
);
