import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { IPreferredWardEntity } from '../../interfaces/api/preferred-ward-entity';
import { IPreferredWardEntityState } from './interfaces';
import { paginationAdapter } from './preferred-ward.adapter';

export const PreferredWardPaginationMessages = paginationAdapter.getMessages();

export class UpsertPreferredWardPageMessage extends PreferredWardPaginationMessages.UpsertPageMessage {}
export class UpsertPreferredWardMultiplePagesMessage extends PreferredWardPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetPreferredWardPaginationMessage extends PreferredWardPaginationMessages.ResetPaginationMessage {}

export enum PreferredWardMessageTypes {
  SET_COLLECTION = '[PreferredWard][Message] Set Collection',
  ADD_ONE = '[PreferredWard][Message] Add One',
  UPDATE_ONE = '[PreferredWard][Message] Update One',
  UPSERT_ONE = '[PreferredWard][Message] Upsert One',
  DELETE_ONE = '[PreferredWard][Message] Delete One',
  ADD_MULTIPLE = '[PreferredWard][Message] Add All',
  DELETE_MULTIPLE = '[PreferredWard][Message] Delete Many',
  UPSERT_MULTIPLE = '[PreferredWard][Message] Upsert Many',
  UPDATE_MULTIPLE = '[PreferredWard][Message] Update Many',
  UPDATE_ENTITY_STATE = '[PreferredWard][Message] Update Entity State',
  SET_SELECT_UNASSIGNED = '[PreferredWard][Message] Set Select Unassigned',
}
export class SetCollectionMessage implements Action {
  readonly type = PreferredWardMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IPreferredWardEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = PreferredWardMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IPreferredWardEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = PreferredWardMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IPreferredWardEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = PreferredWardMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IPreferredWardEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = PreferredWardMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IPreferredWardEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = PreferredWardMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IPreferredWardEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = PreferredWardMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IPreferredWardEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = PreferredWardMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = PreferredWardMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export class UpdateEntityStateMessage implements Action {
  readonly type = PreferredWardMessageTypes.UPDATE_ENTITY_STATE;

  constructor(
    public payload: {
      preferredWardTempState: IPreferredWardEntityState;
    }
  ) {}
}

export class SetSelectUnassignedMessage implements Action {
  readonly type = PreferredWardMessageTypes.SET_SELECT_UNASSIGNED;

  constructor(public payload: { selectUnassigned: boolean }) {}
}

export type PreferredWardMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpdateEntityStateMessage
  | SetSelectUnassignedMessage;
