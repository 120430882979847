import { Action } from '@ngrx/store';

import { Update } from '@ngrx/entity';
import {
  IHospitalEntity,
  IHospitalConfigurationEntity,
} from '../../interfaces/api/hospital-entity';
import { paginationAdapter } from './hospital.adapter';

export const hospitalPaginationMessages = paginationAdapter.getMessages();

export class UpsertHospitalPageMessage extends hospitalPaginationMessages.UpsertPageMessage {}
export class UpsertHospitalMultiplePagesMessage extends hospitalPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetHospitalPaginationMessage extends hospitalPaginationMessages.ResetPaginationMessage {}

export enum HospitalMessageTypes {
  SET_COLLECTION = '[Hospital][Message] Set Collection',
  UPSERT_CONFIGURATION = '[Hospital][Message] Upsert Configuration',
  ADD_ONE = '[Hospital][Message] Add One',
  UPDATE_ONE = '[Hospital][Message] Update One',
  UPSERT_ONE = '[Hospital][Message] Upsert One',
  DELETE_ONE = '[Hospital][Message] Delete One',
  ADD_MULTIPLE = '[Hospital][Message] Add All',
  DELETE_MULTIPLE = '[Hospital][Message] Delete Many',
  UPSERT_MULTIPLE = '[Hospital][Message] Upsert Many',
  UPDATE_MULTIPLE = '[Hospital][Message] Update Many',
  SET_ASSIGNED = '[Hospital][Message] Set Assigned',
}
export class SetCollectionMessage implements Action {
  readonly type = HospitalMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IHospitalEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = HospitalMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IHospitalEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = HospitalMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IHospitalEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = HospitalMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IHospitalEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = HospitalMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IHospitalEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = HospitalMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IHospitalEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = HospitalMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IHospitalEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = HospitalMessageTypes.DELETE_ONE;

  constructor(public payload: { id: string }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = HospitalMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: string[] }) {}
}

export class SetAssignedMessage implements Action {
  readonly type = HospitalMessageTypes.SET_ASSIGNED;

  constructor(public payload: { id: number }) {}
}

export class UpdateConfigurationMessage implements Action {
  readonly type = HospitalMessageTypes.UPSERT_CONFIGURATION;

  constructor(public payload: { id: number; entity: IHospitalConfigurationEntity }) {}
}

export type HospitalMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | SetAssignedMessage
  | UpdateConfigurationMessage;
