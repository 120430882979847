import {
  createPaginatedStateAdapter,
  MessageableFactory,
  SignalableFactory,
} from '@locumsnest/core';
import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IHospitalEntity } from '../../interfaces/api/hospital-entity';

export const adapter: EntityAdapter<IHospitalEntity> = createEntityAdapter<IHospitalEntity>({});

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedHospitalId: null,
  assignedHospitalId: null,
});

export const messageableFactory = MessageableFactory.forFeature<'Hospital'>('Hospital');

export const signalableFactory = SignalableFactory.forFeature<'Hospital'>('Hospital');

export const paginationAdapter = createPaginatedStateAdapter(
  signalableFactory,
  messageableFactory,
  adapter
);
